import { useParams } from "react-router-dom";
import { Form, Pie, Pulse } from "../template";
import { formatDate } from "../utils/format";

const CaseView = () => {
    const { id } = useParams();
    const props = {
        editable: false,
        model: "case",
        id,
    };

    return (
        <>
            <h1 className="bordered">Кэйс</h1>
            <Form {...props}>
                {({ values }) => {
                    if (!values) return null;

                    const { UIC, total, viewed, trainings } = values;

                    return (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div className="p-4 bg-white">
                                <div className="mb-4 text-xl font-bold">
                                    UIC: {UIC}
                                </div>
                                <div className="text-sm">
                                    {trainings.map(
                                        ({ name, viewed, last }, index) => (
                                            <div
                                                key={index}
                                                className="mb-4 pb-4 border-b border-dashed"
                                            >
                                                <div className="relative">
                                                    <Pulse
                                                        color={
                                                            viewed > 0
                                                                ? "bg-def-green"
                                                                : "bg-def-red"
                                                        }
                                                    />{" "}
                                                    Сургалт {index + 1}. {name}
                                                </div>
                                                <div className="flex justify-between text-gray-400 text-xs">
                                                    <div>
                                                        Хамрагдсан тоо: {viewed}
                                                    </div>
                                                    {last && (
                                                        <div>
                                                            Сүүлд хамрагдсан:{" "}
                                                            {formatDate(last)}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                            <div className="p-4 bg-white">
                                Сургалтанд хамрагдсан байдал
                                <Pie
                                    data={[
                                        {
                                            value: viewed,
                                            name: "Хамрагдсан",
                                        },
                                        {
                                            value: total - viewed,
                                            name: "Хамрагдаагүй",
                                        },
                                    ]}
                                    height={300}
                                    renderLabel={({ name, value }) =>
                                        `${name}: ${value}`
                                    }
                                />
                            </div>
                        </div>
                    );
                }}
            </Form>
        </>
    );
};

export default CaseView;
