import { useEffect, useState } from "react";
import { Loader } from "../template";
import { mainApi } from "../utils/api";
import DashboardCard from "../template/DashboardCard";

const Dashboard = () => {
	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);

	useEffect(() => {
		fetch();
	}, []);

	const fetch = async () => {
		setLoading(true);
		const response = await mainApi("/public/training-contents");
		if (response) {
			setItems(response.data.items);
		}
		setLoading(false);
	};

	return (
		<div>
			<h1 className="bordered">Хянах самбар</h1>
			{loading && <Loader />}
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
				{items.map(({ _id, ...item }) => {
					return <DashboardCard key={_id} {...item} hideButton />;
				})}
			</div>
		</div>
	);
};

export default Dashboard;
