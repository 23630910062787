import { Link } from "react-router-dom";
import objectPath from "object-path";
import { API_ROOT, DATE_FORMAT } from "../defines";
import { format } from "date-fns";
import { HiLink } from "react-icons/hi";
import formatDuration from "format-duration";
import humanFormat from "human-format";

export const formatString = (object, path) => {
    return objectPath.get(object, path);
};

export const formatDate = (object, path) => {
    const date = objectPath.get(object, path);

    if (date) return format(new Date(date), DATE_FORMAT);
};

export const formatSize = (object, path) => {
    const size = objectPath.get(object, path);

    if (size) {
        const decimals = 2;
        if (size === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(size) / Math.log(k));

        return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
};

export const formatPrice = (object, path) => {
    const price = objectPath.get(object, path);

    if (!price) return "0.00";

    var amount = parseFloat(price).toFixed(2);
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatLink = (object, displayPath, url) => {
    const display = objectPath.get(object, displayPath);

    return (
        <Link to={url} className="underline flex items-center">
            <HiLink className="mr-1 flex-shrink-0" /> {display}
        </Link>
    );
};

export const formatMedia = (object, srcPath, altPath, className) => {
    const src = objectPath.get(object, `${srcPath}.0.id`);
    const type = objectPath.get(object, `${srcPath}.0.mime`);

    if (src) {
        if (["video/mp4"].includes(type))
            return (
                <video
                    className={`${className ?? "w-32 mx-auto"}`}
                    crossOrigin="anonymous"
                    src={API_ROOT + "/" + src}
                    alt={objectPath.get(object, altPath)}
                />
            );
        return (
            <img
                className={`${className ?? "w-32 mx-auto"}`}
                crossOrigin="anonymous"
                src={API_ROOT + "/" + src}
                alt={objectPath.get(object, altPath)}
            />
        );
    }
};

export const upperFirst = (object, path) => {
    const value = objectPath.get(object, path);

    if (value) return <div className="first">{value}</div>;
};

export const truncateString = (object, path) => {
    const value = path ? objectPath.get(object, path) : object;

    if (value) return <div className="flex w-32 truncate">{value}...</div>;
};

export const createdByCol = {
    key: "createdby",
    label: "fields.createdby",
    filter: {
        type: "select",
        axio: `/const/select/user`,
        // eslint-disable-next-line no-template-curly-in-string
        labelTemplate: "${firstname} (${position})",
    },
    render: ({ createdby }) => {
        if (createdby) {
            const image = objectPath.get(createdby, "profile.0.id");
            const { firstname, position } = createdby;

            return (
                <div className="flex items-center w-64">
                    <img
                        className="w-12 h-12 border rounded-full object-contain mr-1"
                        crossOrigin="anonymous"
                        src={
                            image
                                ? API_ROOT + "/" + image
                                : "/images/profile.png"
                        }
                        alt={firstname}
                    />
                    <div className="whitespace-normal">
                        <div className="uppercase">{firstname}</div>
                        <div>{position}</div>
                    </div>
                </div>
            );
        }
    },
};

export const translateCell = (object, t, root, path) => {
    const value = objectPath.get(object, path);

    if (!value) return;

    if (value) return t(`const.${root}.${value}`);
};

export const nameFormat = (firstname, lastname) => {
    let first = firstname;

    if (firstname) first = first[0].toUpperCase() + first.substring(1);
    if (lastname) return `${lastname[0].toUpperCase()}.${first}`;

    return first;
};

export const durationCalculate = (videos = []) => {
    const total = videos.reduce((prev, current) => {
        return prev + parseFloat(current.duration);
    }, 0);

    return formatDuration(total * 1000, { leading: true });
};

export const durationFormat = (duration) => {
    return formatDuration(parseFloat(duration) * 1000, { leading: true });
};

export const viewFormat = (views = 0, options = { decimals: 1 }) => {
    return humanFormat(views, options);
};
