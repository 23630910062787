import { useNavigate } from "react-router-dom";
import { HiArrowNarrowLeft } from "react-icons/hi";

const Back = () => {
    const navigate = useNavigate();

    return (
        <button
            className="gray"
            type="button"
            onClick={() => {
                navigate(-1);
            }}
        >
            <HiArrowNarrowLeft className="text-lg mr-1" />
            Буцах
        </button>
    );
};

export default Back;
