import { useEffect } from "react";
import { Link, NavLink, Routes, Route } from "react-router-dom";
import { useAuth } from "../auth/provider";
import { useLocation } from "react-router-dom";
import { Footer, Loader } from "../template";
import {
    RiLogoutBoxRLine,
    RiVideoLine,
    RiDashboardLine,
    RiEditBoxLine,
    RiUserAddLine,
    RiLineChartLine,
} from "react-icons/ri";

import Dashboard from "../pages/Dashboard";
import TrainingTable from "../training/Table";
import TrainingView from "../training/View";
import CaseTable from "../case/Table";
import CaseView from "../case/View";
import TrainingContentTable from "../training-content/Table";
import TrainingContentForm from "../training-content/Form";
import Report from "../pages/Report";

const MENU = [
    {
        path: "",
        label: "Дашбоард",
        icon: <RiDashboardLine />,
    },
    {
        path: "training",
        label: "Сургалт",
        icon: <RiEditBoxLine />,
    },
    {
        path: "case",
        label: "Кэйс",
        icon: <RiUserAddLine />,
    },
    {
        path: "training-content",
        label: "Сургалтын контент",
        icon: <RiVideoLine />,
    },
    {
        path: "report",
        label: "Тайлан",
        icon: <RiLineChartLine />,
    },
];

const ROUTES = [
    { path: "/", element: <Dashboard /> },

    { path: "training", element: <TrainingTable /> },
    { path: "training/new", element: <TrainingView /> },
    { path: "training/:id", element: <TrainingView /> },

    { path: "case", element: <CaseTable /> },
    { path: "case/new", element: <CaseView /> },
    { path: "case/:id", element: <CaseView /> },

    { path: "training-content", element: <TrainingContentTable /> },
    { path: "training-content/new", element: <TrainingContentForm /> },
    { path: "training-content/:id", element: <TrainingContentForm /> },
    { path: "report", element: <Report /> },
];

const AdminLayout = () => {
    const { user } = useAuth();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);

    if (!user) return <Loader />;

    return (
        <div className="h-full flex flex-col">
            <div className="h-14 p-2 bg-white flex flex-shrink-0 justify-between items-center shadow-md">
                <img
                    src="/images/logo_text_stroke.png"
                    className="h-10 self-center"
                    alt="Төгс бүсгүйчүүд ТББ"
                />
                <Link to="/login" className="cursor flex items-center text-sm">
                    {user.name}
                    <RiLogoutBoxRLine className="ml-1 text-xl" />
                </Link>
            </div>
            <div className="flex flex-1">
                <div className="w-64 flex-shrink-0 bg-white overflow-y-auto border-r">
                    {MENU.map(({ icon, label, path }) => (
                        <NavLink
                            key={path}
                            to={path}
                            end={path === ""}
                            className={({ isActive }) =>
                                `${
                                    isActive ? "text-white bg-def-primary" : ""
                                } flex items-center p-2 border-t text-sm cursor`
                            }
                        >
                            <span className="text-xl flex-shrink-0 mr-1">
                                {icon}
                            </span>
                            {label}
                        </NavLink>
                    ))}
                </div>
                <div className="flex-1 flex flex-col overflow-x-auto">
                    <div className="flex-1 p-4">
                        <Routes>
                            {ROUTES.map(({ path, element }, index) => (
                                <Route
                                    key={index}
                                    index={index === 0}
                                    path={path}
                                    element={element}
                                />
                            ))}
                        </Routes>
                    </div>

                    <div className="flex-shrink-0 h-8 p-2 bg-white text-xs flex items-center justify-end">
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminLayout;
