import ReactDatePicker from "react-datetime";

import "moment/locale/mn";
import "moment/locale/en-in";
import "moment/locale/zh-cn";

import "react-datetime/css/react-datetime.css";

const DatePicker = ({
    placeholder,
    disabled,
    form,
    field,
    value,
    onChange,
    selected,
    time,
    format = "YYYY-MM-DD",
    ...restProps
}) => {
    const parseValue = (form, field, value) => {
        let tmp = form ? field.value : value;

        if (tmp) tmp = new Date(tmp);

        return tmp;
    };

    const pickerProps = {
        ...restProps,
        className: "w-full",
        locale: "mn",
        viewMode: "days",
        dateFormat: format,
        timeFormat: time ? "HH:mm:ss" : false,
        closeOnSelect: true,
        inputProps: {
            placeholder: placeholder || (time ? "YYYY-MM-DD HH:mm:ss" : format),
            disabled: disabled,
        },
        selected: form ? field.value : selected,
        value: parseValue(form, field, value),
        onChange: (value) => {
            if (value === "" || value._isAMomentObject) {
                form ? form.setFieldValue(field.name, value) : onChange(value);
            }
        },
    };

    return <ReactDatePicker {...pickerProps} />;
};

export default DatePicker;
