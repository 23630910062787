import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import Table from "../list/Table";
import { formatDate, formatLink, formatMedia } from "../utils/format";

const TrainingContentTable = () => {
	return (
		<div>
			<h1 className="bordered">Сургалтын контентууд</h1>
			<div className="bg-white p-4 w-full">
				<Table
					url="/training-content/table"
					renderHeader={() => {
						return (
							<div className="flex justify-end">
								<div className="mb-4">
									<Link to="new" className="button primary">
										Шинээр бүртгэх
										<HiArrowNarrowRight className="text-lg ml-1" />
									</Link>
								</div>
							</div>
						);
					}}
					columns={[
						{
							key: "thumbnail",
							label: "Зураг",
							width: 90,
							render: (row) => formatMedia(row, "thumbnail", "name"),
						},
						{
							key: "name",
							label: "Сургалтын нэр",
							sortable: true,
							filter: true,
							width: 300,
							render: (row) => formatLink(row, "name", `${row._id}`),
						},
						{
							key: "info",
							label: "Товч тайлбар",
							sortable: true,
							filter: true,
							width: 400,
						},
						{
							key: "views",
							label: "Үзсэн тоо",
							sortable: true,
							filter: { type: "number" },
						},
						{
							key: "rate_total",
							label: "Нийт үнэлсэн тоо",
							sortable: true,
							filter: { type: "number" },
						},
						{
							key: "rate_average",
							label: "Дундаж үнэлгээ",
							sortable: true,
							filter: { type: "number" },
							render: ({ rate_average }) => rate_average?.toFixed(2),
						},
						{
							key: "priority",
							label: "Харуулах эрэмбэ",
							sortable: true,
							filter: { type: "number" },
						},
						{
							key: "created",
							label: "Бүртгэсэн огноо",
							sortable: true,
							filter: { type: "date" },
							render: (row) => formatDate(row, "created"),
						},
					]}
				/>
			</div>
		</div>
	);
};

export default TrainingContentTable;
