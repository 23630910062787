import { HiOutlineStar, HiStar } from "react-icons/hi";
import { viewFormat } from "../utils/format";

const Rating = ({ rate, total, hidetotal }) => {
    return (
        <div className="flex text-def-orange items-center">
            {rate > 0 ? <HiStar /> : <HiOutlineStar />}
            {rate > 1 ? <HiStar /> : <HiOutlineStar />}
            {rate > 2 ? <HiStar /> : <HiOutlineStar />}
            {rate > 3 ? <HiStar /> : <HiOutlineStar />}
            {rate > 4 ? <HiStar /> : <HiOutlineStar />}
            {!hidetotal && (
                <span className="text-gray-400">
                    ({viewFormat(total, null)})
                </span>
            )}
        </div>
    );
};

export default Rating;
