import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { API_ROOT, DEBUG } from "../defines";
import { Loader, Field, Back } from "../template";
import { Radios, Text } from "../inputs";
import { mainApi } from "../utils/api";
import TrainingCard from "../template/TrainingCard";
import { successAlert, translateError } from "../utils/alert";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { durationFormat } from "../utils/format";

const RATINGS = [1, 2, 3, 4, 5];
const Pre = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState("Эхлүүлсэн");
	const [selected, setSelected] = useState(0);
	const [rate, setRate] = useState();
	const [training, setTraining] = useState();
	const [answers, setAnswers] = useState({});
	const [searchParam] = useSearchParams();
	const [UIC, setUIC] = useState(searchParam.get("UIC"));
	const [trainingContent, setTrainingContent] = useState({
		questions: [],
		video: [],
	});
	const { questions, video, name } = trainingContent;

	useEffect(() => {
		if (id) fetch(id);
	}, [id]);

	const fetch = async (id) => {
		setLoading(true);
		const response = await mainApi(`/public/training-contents/${id}`);
		if (response) {
			setTrainingContent(response.data);
		}
		setLoading(false);
	};

	const formik = {
		enableReinitialize: true,
		initialValues: {
			father: DEBUG ? "да" : "",
			mother: DEBUG ? "мя" : "",
			date: DEBUG ? "9911" : "",
		},
		validationSchema: Yup.object().shape({
			father: Yup.string()
				.length(2, "Зөвхөн 2 үсэг оруулна")
				.matches(
					/[АБВГДЕЁЖЗИЙКЛМНОӨПРСТУҮФХЦЧШЩЪЫЬЭЮЯабвгдеёжзийклмноөпрстуүфхцчшщъыьэюя]{2}/,
					"Зөвхөн крилл үсэг оруулна уу"
				)
				.required("Эцгийн нэрний эхний 2 үсэг оруулна уу"),
			mother: Yup.string()
				.length(2, "Зөвхөн 2 үсэг оруулна")
				.matches(
					/[АБВГДЕЁЖЗИЙКЛМНОӨПРСТУҮФХЦЧШЩЪЫЬЭЮЯабвгдеёжзийклмноөпрстуүфхцчшщъыьэюя]{2}/,
					"Зөвхөн крилл үсэг оруулна уу"
				)
				.required("Эхийн нэрний 2 үсэг оруулна уу"),
			date: Yup.string()
				.matches(/[0-9]{2}(0[1-9]|1[0-2])/, "Төрсөн он сар зөв оруулна уу")
				.length(4, "Зөвхөн 4 тоо оруулна")
				.required("Төрсөн он сар оруулна уу"),
		}),
		onSubmit: async ({ father, mother, date }, { setSubmitting }) => {
			try {
				if (Object.keys(answers).length !== questions.length) {
					throw new Error("Сорилын бүх асуултуудад хариулна уу!");
				}

				if (progress === "Эхлүүлсэн") {
					if (!father || !mother || !date) {
						throw new Error("Тодорхойлох код бүрэн оруулна уу!");
					}
					const uic = (UIC || `${father}${mother}${date}`).toUpperCase();
					const response = await mainApi({
						url: "/public/training",
						method: "POST",
						data: {
							training: id,
							UIC: uic,
							answers,
						},
					});
					setUIC(uic);
					setTraining(response.data);
					setProgress("Үзэж байгаа");
				}

				if (progress === "Дууссан") {
					if (!rate) {
						throw new Error("Сургалтанд үнэлгээ өгнө үү!");
					}
					const response = await mainApi({
						url: `/public/training/${training}/finish`,
						method: "POST",
						data: {
							UIC,
							rate,
							answers,
						},
					});
					await successAlert(
						`Та манай "${name}" цахим сургалтанд амжилттай хамрагдлаа.<br/><br/>Та өөрийн "Тодорхойлох код"-оор манай эрүүл мэндийн үйлчилгээнд үнэ төлбөргүй хамрагдах боломжтой.<br/><br/>${
							response.data.percent
						}% зөв хариулсан байна.<br/>${
							response.data.pass ? "Та сертификатаа авна уу" : ""
						}`,
						"Баяр хүргэе!"
					);
					if (response.data.pass) {
						navigate(`/public/certificate/${response.data.id}`);
					} else {
						navigate("/public");
					}
				}

				setSubmitting(false);
			} catch (error) {
				translateError(error);
			}
		},
	};

	const onEnded = async (index) => {
		if (index === video.length - 1) {
			setProgress("Дууссан");
			setAnswers({});
		} else {
			setSelected(index + 1);
		}

		try {
			await mainApi({
				url: `/public/training/${training}/viewed`,
				method: "POST",
				data: {
					video: index,
				},
			});
		} catch (error) {
			translateError(error);
		}
	};

	return (
		<div>
			{loading && <Loader />}
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
				<TrainingCard {...trainingContent} hideButton />
				<div className="p-4 bg-white rounded shadow-md col-span-1 lg:col-span-2">
					<Formik {...formik}>
						{({ isSubmitting }) => {
							const isLoading = isSubmitting;

							return (
								<Form>
									{isLoading && <Loader />}
									{!UIC && (
										<div className="mb-4">
											<div className="uppercase text-center">
												Тодорхойлох код
											</div>
											<div className="text-xs normal-case leading-zero mb-4 text-center text-gray-700">
												Доорх мэдээлэл нь таныг илэрхийлэх код болох бөгөөд уг
												кодыг ашиглан та манай эрүүл мэндийн үйлчилгээнд үнэ
												төлбөргүй хамрагдах боломжтой.
											</div>
											<Field
												name="father"
												label="Эцгийн нэрний эхний 2 үсэг"
												helper={() => (
													<div className="text-xs text-gray-700">
														Жишээ нь "Даваа" гэдэг нэртэй бол зөвхөн "да" гэж
														бичнэ.
													</div>
												)}
												component={Text}
											/>
											<Field
												name="mother"
												label="Эхийн нэрний эхний 2 үсэг"
												helper={() => (
													<div className="text-xs text-gray-700">
														Жишээ нь "Мягмар" гэдэг нэртэй бол зөвхөн "мя" гэж
														бичнэ.
													</div>
												)}
												component={Text}
											/>
											<Field
												name="date"
												label="Төрсөн он сар"
												helper={() => (
													<div className="text-xs text-gray-700">
														Жишээ нь 1999 оны 11 сард төрсөн хүн бол 9911 гэж
														бичнэ.
													</div>
												)}
												component={Text}
												type="number"
											/>
										</div>
									)}
									{["Эхлүүлсэн", "Дууссан"].includes(progress) && (
										<div>
											<div className="mb-4 uppercase text-center">
												{progress === "Эхлүүлсэн"
													? "Сургалтын өмнөх сорил"
													: "Сургалтын дараах сорил"}
											</div>
											{Array.isArray(questions) &&
												questions.map(
													({ _id: question_id, question, variants }, index) => {
														return (
															<div
																key={index}
																className="text-sm mb-2 pb-2 border-b border-dashed"
															>
																{question}
																<Radios
																	items={variants}
																	renderItem={({ _id, variant }) => {
																		const selected =
																			answers[question_id] === _id;
																		return (
																			<div className={`flex text-gray-700`}>
																				<span className="mr-2 mt-1 flex-shrink-0">
																					{selected ? (
																						<ImCheckboxChecked />
																					) : (
																						<ImCheckboxUnchecked />
																					)}
																				</span>
																				{variant}
																			</div>
																		);
																	}}
																	onChange={(value) => {
																		setAnswers({
																			...answers,
																			[question_id]: value,
																		});
																	}}
																/>
															</div>
														);
													}
												)}
											{progress === "Дууссан" && (
												<div className="my-4 pb-4 border-b border-dashed">
													<div className="uppercase text-center mb-4">
														Сургалтын үнэлгээ
													</div>
													<div className="flex items-center justify-center gap-2">
														{RATINGS.map((point) => (
															<div
																key={point}
																className={`px-4 py-2 border rounded cursor ${
																	rate === point
																		? "bg-def-secondary text-white"
																		: ""
																}`}
																onClick={() => {
																	setRate(point);
																}}
															>
																{point}
															</div>
														))}
													</div>
												</div>
											)}
											<div className="flex gap-4">
												{progress === "Эхлүүлсэн" && <Back />}
												{progress === "Дууссан" && (
													<button
														className="gray"
														onClick={() => {
															setProgress("Үзэж байгаа");
														}}
													>
														<HiArrowNarrowLeft className="mr-1" />
														Дахин үзэх
													</button>
												)}
												<button type="submit" className="secondary">
													{progress === "Дууссан" ? "Илгээх" : "Эхлүүлэх"}
													<HiArrowNarrowRight className="ml-1" />
												</button>
											</div>
										</div>
									)}
									{progress === "Үзэж байгаа" && (
										<div>
											<video
												key={selected}
												autoPlay={true}
												className="w-full"
												controls="controls"
												crossOrigin="anonymous"
												onEnded={() => {
													onEnded(selected);
												}}
											>
												<source
													src={API_ROOT + "/" + video[selected].id}
												></source>
											</video>
											<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4">
												{video.map(({ duration }, index) => (
													<div
														key={index}
														className={`p-4 cursor border text-sm rounded ${
															selected === index
																? "border-def-secondary bg-def-secondary bg-opacity-20"
																: ""
														}`}
														onClick={() => {
															setSelected(index);
														}}
													>
														{index + 1}-р хэсэг {durationFormat(duration)}
													</div>
												))}
												<button
													className="secondary"
													onClick={() => {
														setProgress("Дууссан");
														setAnswers({});
													}}
												>
													Дуусгах
													<HiArrowNarrowRight className="ml-1" />
												</button>
											</div>
										</div>
									)}
								</Form>
							);
						}}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default Pre;
