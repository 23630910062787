import { useParams } from "react-router-dom";
import { Form, Pie, Rating } from "../template";
import { formatDate } from "../utils/format";
import Certificate from "../template/Certificate";

const TrainingView = () => {
	const { id } = useParams();
	const props = {
		editable: false,
		model: "training",
		id,
	};

	return (
		<>
			<h1 className="bordered">Сургалт</h1>
			<Form {...props}>
				{({ values = { final: {}, pre: {}, content: {} } }) => {
					const {
						_id,
						UIC,
						content,
						created,
						final,
						pre,
						diff,
						finished,
						video_total,
						video_viewed,
						rate,
						status,
					} = values;

					return (
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="p-4 bg-white">
								<div className="text-xl font-bold">
									UIC: {UIC}
									<br />
								</div>
								<div className="border-b border-dashed mb-4 pb-4">
									Сургалт: {content?.name}
								</div>
								<div className="text-sm text-gray-700">
									<div className="flex justify-between">
										<div>Үзэж эхэлсэн огноо</div>
										<div>{formatDate(created)}</div>
									</div>
									<div className="flex justify-between">
										<div>Үзэж дууссан огноо</div>
										<div>{formatDate(finished)}</div>
									</div>
									<div className="flex justify-between">
										<div>Төлөв</div>
										<div>{status}</div>
									</div>
									<div className="flex justify-between my-4">
										<div>Сургалтанд өгсөн үнэлгээ</div>
										<div>
											<Rating rate={rate} hidetotal />
										</div>
									</div>
									<div className="flex justify-between">
										<div>Өмнөх сорилын дүн</div>
										<div>{`${pre?.earned} оноо / ${pre?.percent}%`}</div>
									</div>
									<div className="flex justify-between">
										<div>Дараах сорилын дүн</div>
										<div>{`${final?.earned} оноо / ${final?.percent}%`}</div>
									</div>
									<div className="flex justify-between">
										<div>Өөрчлөлт</div>
										<div className="relative">
											<div>{`${diff?.earned} оноо / ${diff?.percent}%`}</div>
										</div>
									</div>
								</div>
								<div className="mt-4" key={_id}>
									<Certificate
										status={status}
										UIC={UIC}
										training={content?.name}
										finished={finished}
										percent={final.percent}
									/>
								</div>
								<Pie
									data={[
										{
											value: video_viewed,
											name: "Үзсэн видео",
										},
										{
											value: video_total - video_viewed,
											name: "Үзээгүй видео",
										},
									]}
									height={300}
									renderLabel={({ name, value }) => `${name}: ${value}`}
								/>
							</div>
							<div className="p-4 bg-white">
								<div className="border-b border-dashed mb-4 pb-4">
									Өмнөх сорилын хариултууд
								</div>
								<div className="text-sm leading-tight">
									{pre.result?.map(([question, answer, earned], index) => (
										<div key={index} className="mb-4">
											<div>{question}</div>
											<div className="mx-4 text-xs text-gray-700">
												({earned}) {answer}
											</div>
										</div>
									))}
								</div>
								<div className="border-b border-dashed mb-4 pb-4 mt-8">
									Дараах сорилын хариултууд
								</div>
								<div className="text-sm leading-tight">
									{final.result?.map(([question, answer, earned], index) => (
										<div key={index} className="mb-4">
											<div>{question}</div>
											<div className="mx-4 text-xs text-gray-700">
												({earned}) {answer}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					);
				}}
			</Form>
		</>
	);
};

export default TrainingView;
