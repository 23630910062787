import { useEffect } from "react";
import { Link, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Footer } from "../template";
import { RiLoginBoxLine } from "react-icons/ri";

import Home from "../pages/Home";
import Training from "../pages/Training";
import Certificate from "../pages/Certificate";

const ROUTES = [
    { path: "/", element: <Home /> },
    { path: "training/:id", element: <Training /> },
    { path: "certificate/:id", element: <Certificate /> },
];

const PublicLayout = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);

    return (
        <div className="h-full flex flex-col">
            <div className="h-14 p-2 bg-white flex-shrink-0 shadow-md">
                <div className="container mx-auto flex justify-between items-center">
                    <Link to="/public" className="cursor">
                        <img
                            src="/images/logo_text_stroke.png"
                            className="h-10 self-center"
                            alt="Төгс бүсгүйчүүд ТББ"
                        />
                    </Link>
                    <Link
                        to="/login"
                        className="cursor flex items-center text-sm"
                    >
                        Нэвтрэх
                        <RiLoginBoxLine className="ml-1 text-xl" />
                    </Link>
                </div>
            </div>
            <div className="flex-1 flex flex-col">
                <div className="flex-1 container mx-auto p-4 relative">
                    <Routes>
                        {ROUTES.map(({ path, element }, index) => (
                            <Route
                                key={index}
                                index={index === 0}
                                path={path}
                                element={element}
                            />
                        ))}
                    </Routes>
                </div>
                <div className="flex-shrink-0 h-8 p-2 bg-white text-xs">
                    <div className="container mx-auto flex items-center justify-end">
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicLayout;
