import { durationCalculate } from "../utils/format";
import { HiOutlineClock, HiOutlineBookOpen } from "react-icons/hi";
import { Rating } from ".";

const DashboardCard = (item) => {
    const { name, video, views = 0, rate_average = 0, rate_total = 0 } = item;

    return (
        <div className="bg-white p-4 rounded shadow-md flex flex-col justify-between">
            <div className="text-sm uppercase leading-tight my-4">{name}</div>
            <div className="flex items-center justify-between text-sm mb-2 text-gray-400">
                <div>
                    <Rating rate={rate_average} total={rate_total} hidetotal />
                    <div className="flex items-center">
                        <HiOutlineBookOpen className="mr-1" />
                        {video?.length} видео
                    </div>
                    <div className="flex items-center">
                        <HiOutlineClock className="mr-1" />
                        {durationCalculate(video)}
                    </div>
                </div>
                <div className="text-gray-700 text-right">
                    <div className="text-4xl">{views}</div>
                    <div>үзсэн</div>
                </div>
            </div>
        </div>
    );
};

export default DashboardCard;
