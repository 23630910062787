import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Loader } from "./template";
import AuthProvider from "./auth/provider";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import AdminLayout from "./layouts/Admin";
import PublicLayout from "./layouts/Public";

ReactDOM.render(
    <BrowserRouter>
        <React.StrictMode>
            <Suspense fallback={<Loader />}>
                <AuthProvider>
                    <Routes>
                        <Route path="/" element={<Navigate to="public" />} />
                        <Route path="login" element={<Login />} />
                        <Route path="public/*" element={<PublicLayout />} />
                        <Route path="admin/*" element={<AdminLayout />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </AuthProvider>
            </Suspense>
        </React.StrictMode>
    </BrowserRouter>,
    document.getElementById("root")
);
