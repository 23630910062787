import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    GetFromStorage,
    RemoveFromStorage,
    SaveToStorage,
} from "../utils/storage";
import { mainApi } from "../utils/api";

const AuthContext = React.createContext({
    user: null,
    login: () => {},
    logout: () => {},
});

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState();

    const login = async (instance) => {
        setInstance(instance);
        navigate("/admin");
    };

    const logout = () => {
        setInstance();
    };

    const setInstance = (instance) => {
        if (instance) {
            SaveToStorage("refreshToken", instance.refreshToken);
            SaveToStorage("accessToken", instance.accessToken);
            setUser(instance.user);
        } else {
            RemoveFromStorage("refreshToken");
            RemoveFromStorage("accessToken");
            setUser(null);
        }
    };

    useEffect(() => {
        const refreshUserToken = async (token) => {
            if (token) {
                const response = await mainApi({
                    method: "POST",
                    url: "/user/refresh",
                    data: {
                        token,
                    },
                });
                response && setInstance(response.data);
            }
        };

        refreshUserToken(GetFromStorage("refreshToken"));
    }, []);

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthContext);
export default AuthProvider;
