import { useEffect } from "react";
import { VERSION, DEBUG } from "../defines";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import sha256 from "crypto-js/sha256";
import { Loader, Field } from "../template";
import { useAuth } from "../auth/provider";
import { mainApi } from "../utils/api";
import { translateError } from "../utils/alert";
import { Text } from "../inputs";
import { Link } from "react-router-dom";
import { HiArrowNarrowLeft } from "react-icons/hi";

const Login = () => {
    const { login, logout } = useAuth();

    useEffect(() => {
        logout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = {
        enableReinitialize: true,
        initialValues: {
            username: DEBUG ? "" : "",
            password: DEBUG ? "" : "",
        },
        validationSchema: Yup.object().shape({
            username: Yup.string().required("Хэрэглэгчийн нэр оруулна уу"),
            password: Yup.string().required("Нууц үг оруулна уу"),
        }),
        onSubmit: async ({ username, password }, { setSubmitting }) => {
            try {
                const data = {
                    username,
                    password: sha256(password).toString(),
                };
                const response = await mainApi({
                    url: "/user/login",
                    method: "POST",
                    data,
                });

                setSubmitting(false);
                response && login(response.data);
            } catch (error) {
                translateError(error);
            }
        },
    };
    
    return (
        <div className="h-full grid grid-cols-1 md:grid-cols-2">
            <div className="h-full bg-def-secondary hidden md:flex items-center justify-center relative">
                <div className="text-white text-sm text-center">
                    <img
                        src="/images/logo_stroke.png"
                        alt="Төгс бүсгүйчүүд ТББ"
                        className="w-32 mx-auto"
                    />
                    <div className="text-xl">Төгс бүсгүйчүүд</div>
                    <div className="text-sm">Цахим сургалтын систем</div>
                    <div className="text-sm">v{VERSION}</div>
                </div>
                <div className="absolute px-5">
                    <img
                        src="/images/bg-login.png"
                        alt="background"
                        className="w-full object-cover opacity-5"
                    />
                </div>
            </div>
            <div className="h-full bg-white flex items-center justify-center">
                <div className="w-full lg:w-2/3 mx-auto px-4 py-8 relative">
                    <h1 className="uppercase text-center mb-8 text-xl">
                        системд Нэвтрэх
                    </h1>
                    <Formik {...formik}>
                        {({ isSubmitting }) => {
                            const isLoading = isSubmitting;

                            return (
                                <Form>
                                    {isLoading && <Loader />}
                                    <Field
                                        name="username"
                                        label="Хэрэглэгчийн нэр"
                                        component={Text}
                                    />
                                    <Field
                                        name="password"
                                        label="Нууц үг"
                                        type="password"
                                        component={Text}
                                    />
                                    <Link
                                        to="/public"
                                        className="link text-right my-8 text-sm flex items-center justify-end"
                                    >
                                        <HiArrowNarrowLeft className="mr-1" />
                                        Нүүр хуудас руу очих
                                    </Link>
                                    <button type="submit" className="secondary">
                                        Нэвтрэх
                                    </button>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default Login;
