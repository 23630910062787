import Swal from "sweetalert2";

export const Alert = Swal.mixin({});

export const translateError = (error) => {
    return Alert.fire({
        html: error.message || error.toString(),
        icon: "warning",
    });
};

export const successAlert = (content, title) => {
    return Alert.fire({
        title,
        html: content || "Амжилттай",
        icon: "success",
        timer: content ? undefined : 1000,
    });
};
