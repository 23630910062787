import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../template";
import { mainApi } from "../utils/api";
import TrainingCard from "../template/TrainingCard";
import Certificate from "../template/Certificate";

const Pre = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    const [training, setTraining] = useState();
    const [content, setContent] = useState();
    const [rest, setRest] = useState([]);

    useEffect(() => {
        if (id) fetch(id);
    }, [id]);

    const fetch = async (id) => {
        setLoading(true);
        const response = await mainApi(`/public/certificate/${id}`);
        if (response) {
            setTraining(response.data.training);
            setContent(response.data.content);
            setRest(response.data.rest);
        }
        setLoading(false);
    };

    return (
        <div>
            {loading && <Loader />}
            <div className="mb-8">
                <Certificate
                    UIC={training?.UIC}
                    training={content?.name}
                    finished={training?.finished}
                    percent={training?.final?.percent}
                />
            </div>
            {rest && rest.length > 0 && (
                <div>
                    <h1 className="bordered">Хамрагдаагүй сургалтууд</h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {rest.map(({ _id, ...item }) => {
                            return (
                                <TrainingCard
                                    key={_id}
                                    {...item}
                                    _id={_id}
                                    hideInfo
                                    justify
                                    UIC={training.UIC}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Pre;
