import { useEffect, useState } from "react";
import { Loader } from "../template";
import { mainApi } from "../utils/api";
import TrainingCard from "../template/TrainingCard";

const Home = () => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch();
    }, []);

    const fetch = async () => {
        setLoading(true);
        const response = await mainApi("/public/training-contents");
        if (response) {
            setItems(response.data.items);
        }
        setLoading(false);
    };

    return (
        <div>
            {loading && <Loader />}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {items.map(({ _id, ...item }) => {
                    return (
                        <TrainingCard
                            key={_id}
                            {...item}
                            _id={_id}
                            hideInfo
                            justify
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Home;
