import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, FieldArray, Form } from "../template";
import { FileUpload, Text } from "../inputs";

const calculateTotal = (questions) => {
    return (
        Array.isArray(questions) &&
        questions.reduce((prev, question) => {
            const max =
                (Array.isArray(question.variants) &&
                    question.variants.reduce(
                        (prev, variant) =>
                            variant.point > prev ? variant.point : prev,
                        0
                    )) ||
                0;
            return parseFloat(prev) + parseFloat(max);
        }, 0)
    );
};

const TrainingContentForm = () => {
    const { id } = useParams();
    const props = {
        editable: true,
        model: "training-content",
        id,
        init: {
            name: "",
            info: "",
            priority: 0,
            threshold: 50,
            video: [],
            thumbnail: [],
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Сургалтын нэр оруулна уу"),
            questions: Yup.array().of(
                Yup.object().shape({
                    question: Yup.string().required("Асуулт оруулна уу"),
                })
            ),
            priority: Yup.number().required("Харуулах эрэмбэ оруулна уу"),
            threshold: Yup.number()
                .required("Босго хувь оруулна уу")
                .min(0, "Босго 0-ээс их байх шаардлагатай")
                .max(100, "Босго 100-аас бага байх шаардлагатай"),
            video: Yup.array().min(1, "Сургалтын видоенууд оруулна уу"),
            thumbnail: Yup.array().min(1, "Нүүр зураг оруулна уу"),
        }),
        beforeSubmit: (values) => {
            return { ...values, total: calculateTotal(values.questions) };
        },
    };

    return (
        <>
            <h1 className="bordered">Сургалтын контент</h1>
            <Form {...props}>
                {({ disabled }) => {
                    return (
                        <div className="p-4 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div>
                                <Field
                                    name="name"
                                    label="Сургалтын нэр"
                                    disabled={disabled}
                                    component={Text}
                                />
                                <Field
                                    name="priority"
                                    label="Харуулах эрэмбэ"
                                    disabled={disabled}
                                    component={Text}
                                    type="number"
                                />
                                <Field
                                    name="threshold"
                                    label="Сорилын босго хувь"
                                    disabled={disabled}
                                    component={Text}
                                    type="number"
                                />
                                <Field
                                    name="info"
                                    label="Товч тайлбар"
                                    disabled={disabled}
                                    component={Text}
                                    type="textarea"
                                    rows={10}
                                />
                                <Field
                                    name="thumbnail"
                                    label="Нүүр зураг"
                                    disabled={disabled}
                                    component={FileUpload}
                                    root="thumbnail"
                                />
                                <Field
                                    name="video"
                                    label="Видео"
                                    disabled={disabled}
                                    component={FileUpload}
                                    root="video"
                                    accept="video/mp4"
                                    count={15}
                                    limit={500}
                                />
                            </div>
                            <div>
                                <FieldArray
                                    name="questions"
                                    label="Сорилын асуултууд"
                                    disabled={disabled}
                                    initial={{ question: "", variants: [] }}
                                    renderItem={(question, qIndex) => (
                                        <div>
                                            <Field
                                                name={`questions.${qIndex}.question`}
                                                label="Асуулт"
                                                disabled={disabled}
                                                component={Text}
                                                type="textarea"
                                            />
                                            <div className="p-4 bg-gray-100">
                                                <FieldArray
                                                    name={`questions.${qIndex}.variants`}
                                                    label="Хариултууд"
                                                    disabled={disabled}
                                                    initial={{
                                                        variant: "",
                                                        point: 0,
                                                    }}
                                                    itemClass="p-4 border"
                                                    renderItem={(
                                                        variant,
                                                        aIndex
                                                    ) => (
                                                        <div className="grid grid-cols-4 gap-2">
                                                            <div className="col-span-3">
                                                                <Field
                                                                    name={`questions.${qIndex}.variants.${aIndex}.variant`}
                                                                    label="Хариулт"
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                    component={
                                                                        Text
                                                                    }
                                                                    type="textarea"
                                                                />
                                                            </div>
                                                            <Field
                                                                name={`questions.${qIndex}.variants.${aIndex}.point`}
                                                                label="Оноо"
                                                                disabled={
                                                                    disabled
                                                                }
                                                                component={Text}
                                                                type="number"
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    );
                }}
            </Form>
        </>
    );
};

export default TrainingContentForm;
