import Table from "../list/Table";
import { formatDate, formatLink } from "../utils/format";

const CaseTable = () => {
    return (
        <div>
            <h1 className="bordered">Кэйсүүд</h1>
            <div className="bg-white p-4 w-full">
                <Table
                    url="/case/table"
                    excelExport="Кэйс тайлан.xlsx"
                    columns={[
                        {
                            key: "UIC",
                            label: "UIC",
                            sortable: true,
                            filter: true,
                            render: (row) =>
                                formatLink(row, "UIC", `${row._id}`),
                        },
                        {
                            key: "viewed",
                            label: "Хамрагдсан сургалт",
                            sortable: true,
                            filter: { type: "number" },
                        },
                        {
                            key: "percent",
                            label: "Хамрагдсан хувь",
                            sortable: true,
                            filter: { type: "number" },
                        },
                        {
                            key: "created",
                            label: "Бүртгэгдсэн огноо",
                            sortable: true,
                            filter: { type: "date" },
                            render: (row) => formatDate(row, "created"),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default CaseTable;
