import Table from "../list/Table";
import { formatDate, formatLink } from "../utils/format";

const TrainingTable = () => {
	return (
		<div>
			<h1 className="bordered">Сургалтанд хамрагдалт</h1>
			<div className="bg-white p-4 w-full">
				<Table
					url="/training/table"
					excelExport="Сургалтын тайлан.xlsx"
					columns={[
						{
							key: "UIC",
							label: "UIC",
							sortable: true,
							filter: true,
							render: (row) => formatLink(row, "UIC", `${row._id}`),
						},
						{
							key: "training",
							label: "Сургалтын нэр",
							sortable: true,
							filter: true,
						},
						{
							key: "video_percent",
							label: "Үзэлтийн хувь",
							sortable: true,
							filter: { type: "number" },
						},
						{
							key: "pre.percent",
							label: "Өмнөх сорил",
							sortable: true,
							filter: { type: "number" },
						},
						{
							key: "final.percent",
							label: "Дараах сорил",
							sortable: true,
							filter: { type: "number" },
						},
						{
							key: "diff.percent",
							label: "Мэдлэгийн өөрчлөлт",
							sortable: true,
							filter: { type: "number" },
						},
						{
							key: "difference",
							label: "Мэдлэгийн түвшин",
						},
						{
							key: "status",
							label: "Төлөв",
							sortable: false,
							filter: true,
						},
						{
							key: "created",
							label: "Үзэж эхэлсэн огноо",
							sortable: true,
							filter: { type: "date" },
							render: (row) => formatDate(row, "created"),
						},
						{
							key: "created",
							label: "Үзэж дууссан огноо",
							sortable: true,
							filter: { type: "date" },
							render: (row) => formatDate(row, "finished"),
						},
						{
							key: "duration",
							label: "Зарцуулсан хугацаа",
							sortable: true,
							filter: { type: "date" },
						},
					]}
				/>
			</div>
		</div>
	);
};

export default TrainingTable;
