import { useEffect, useState } from "react";
import { formatDate } from "../utils/format";
import domtoimage from "dom-to-image";
import { RiDownload2Line } from "react-icons/ri";

const Certificate = ({ training, UIC, finished, percent, status }) => {
	const [image, setImage] = useState();
	const [hidden, setHidden] = useState(false);

	useEffect(() => {
		if (training && UIC && finished && percent) {
			var node = document.getElementById("certificate");
			domtoimage
				.toPng(node)
				.then((base64) => {
					setImage(base64);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [training, UIC, finished, percent]);

	useEffect(() => {
		if (status !== "Сорилд тэнцсэн") {
			setHidden(true);
		}
	}, [status]);

	if (hidden) return null;

	return (
		<div>
			<img
				alt="Сертификат"
				src={image ? image : "/images/certificate.png"}
				className="w-full"
			/>
			{!image && (
				<div
					id="certificate"
					className="relative border mx-auto shadow-xl"
					style={{
						width: "297.25mm",
						height: "210.25mm",
						color: "#1b2e50",
						fontFamily: "Arial",
					}}
				>
					<img
						className="object-fit"
						src={"/images/certificate.png"}
						alt={"Сертификат"}
					/>
					<div style={{ top: "50%" }} className="absolute w-full text-2xl">
						<div className="w-1/2 mx-auto text-center uppercase">
							{training}
							<br />
							<div className="text-base normal-case">
								/сорилын дүн {percent || "0"}%/
							</div>
						</div>
					</div>
					<div style={{ bottom: 110, left: 333 }} className="absolute text-xl">
						<div>{formatDate(finished)}</div>
					</div>
					<div style={{ bottom: 110, right: 380 }} className="absolute text-xl">
						<div>{UIC}</div>
					</div>
				</div>
			)}
			<div
				className="button secondary mt-4 flex items-center justify-center"
				onClick={() => {
					var a = document.createElement("a");
					a.href = image;
					a.download = `${UIC}-${training}.png`;
					a.click();
				}}
			>
				<RiDownload2Line className="mr-1" /> Сертификат татах
			</div>
		</div>
	);
};

export default Certificate;
