import {
    formatMedia,
    durationCalculate,
    formatDate,
    viewFormat,
} from "../utils/format";
import {
    HiOutlineEye,
    HiOutlineArrowNarrowRight,
    HiOutlineClock,
    HiOutlineBookOpen,
    HiOutlineCalendar,
} from "react-icons/hi";
import { Rating } from ".";
import { Link } from "react-router-dom";

const TrainingCard = (item) => {
    const {
        _id,
        name,
        video,
        info,
        views = 0,
        rate_average = 0,
        rate_total = 0,
        created,
        hideButton,
        hideInfo,
        justify,
        UIC,
    } = item;

    return (
        <div
            className={`bg-white p-4 rounded shadow-md flex flex-col ${
                justify ? "justify-between" : "justify-start"
            }`}
        >
            <div>
                {formatMedia(item, "thumbnail", "name", "w-full")}
                <div className="text-sm uppercase text-center leading-tight my-4">
                    {name}
                </div>
                {!hideInfo && (
                    <div className="text-sm text-center leading-tight my-4">
                        {info}
                    </div>
                )}
            </div>
            <div>
                <div className="flex items-center justify-between text-sm mb-2 text-gray-400">
                    <div className="flex items-center">
                        <HiOutlineClock className="mr-1" />
                        {durationCalculate(video)}
                    </div>
                    <div className="flex items-center">
                        <HiOutlineBookOpen className="mr-1" />
                        {video?.length} видео
                    </div>
                </div>
                <div className="flex items-center justify-between text-sm">
                    <div className="flex items-center text-gray-400">
                        <HiOutlineEye className="mr-1" />
                        {viewFormat(views)} үзсэн
                    </div>
                    <div>
                        {!hideButton && (
                            <Link
                                to={`/public/training/${_id}${
                                    UIC ? `?UIC=${UIC}` : ""
                                }`}
                            >
                                <div className="flex items-center button secondary flex-grow-0">
                                    <span className="text-xs">
                                        Сургалт үзэх
                                    </span>
                                    <HiOutlineArrowNarrowRight className="ml-1" />
                                </div>
                            </Link>
                        )}
                    </div>
                </div>
                <div className="flex items-center justify-between text-xs mt-2 pt-2 border-t border-dashed text-gray-400">
                    <div className="flex items-center">
                        <HiOutlineCalendar className="mr-1" />
                        {formatDate(created)}
                    </div>
                    <div className="flex items-center">
                        <Rating rate={rate_average} total={rate_total} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrainingCard;
