import { useEffect, useState } from "react";
import { Loader } from "../template";
import { mainApi } from "../utils/api";
import { DatePicker } from "../inputs";
import moment from "moment";
import { Pie } from "../template";

const Report = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const [start, setStart] = useState(
		moment()
			.startOf("month")
			.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
	);
	const [end, setEnd] = useState(moment().endOf("day"));

	useEffect(() => {
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetch = async () => {
		setLoading(true);
		const response = await mainApi({
			method: "POST",
			url: "/training/report",
			data: { start, end },
		});
		if (response) {
			setData(response.data);
		}
		setLoading(false);
	};

	return (
		<div>
			<h1 className="bordered">Тайлан</h1>
			{loading && <Loader />}
			<div className="p-4 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
					<DatePicker value={start} onChange={setStart} />
					<DatePicker value={end} onChange={setEnd} />
					<button
						className="secondary"
						onClick={() => {
							fetch();
						}}
					>
						Тайлан боловсруулах
					</button>
				</div>
				{data && (
					<div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-8">
						<div className="col-span-2">
							<table className="text-center text-sm w-full">
								<thead>
									<tr>
										<th className="p-2 border bg-gray-100 text-left">
											Сургалтын нэр
										</th>
										<th className="p-2 border bg-gray-100">Бүрэн үзсэн</th>
										<th className="p-2 border bg-gray-100">Дутуу үзсэн</th>
										<th className="p-2 border bg-gray-100">
											Нийт хамрагдсан тоо
										</th>
									</tr>
								</thead>
								<tbody>
									{data.training.map(({ _id, total, finished }, index) => (
										<tr key={index}>
											<td className="p-2 border text-left">{_id}</td>
											<td className="p-2 border">{finished}</td>
											<td className="p-2 border">{total - finished}</td>
											<td className="p-2 border">{total}</td>
										</tr>
									))}
								</tbody>
								<thead>
									<tr>
										<th className="p-2 border bg-gray-100 text-left">Нийт</th>
										<th className="p-2 border bg-gray-100">
											{data.training.reduce(
												(prev, { finished }) => prev + finished,
												0
											)}
										</th>
										<th className="p-2 border bg-gray-100">
											{data.training.reduce(
												(prev, { total, finished }) => prev + total - finished,
												0
											)}
										</th>
										<th className="p-2 border bg-gray-100">
											{data.training.reduce(
												(prev, { total }) => prev + total,
												0
											)}
										</th>
									</tr>
								</thead>
							</table>
						</div>
						<div>
							<div>Сургалтын төлөв</div>
							<Pie
								data={data.status.map(({ _id, total }) => ({
									value: total,
									name: _id || "Эхлүүлээд орхисон",
								}))}
								height={300}
								renderLabel={({ name, value }) => `${name}: ${value}`}
							/>
						</div>
						<div>
							<div>Сургалтын үнэлгээ</div>
							<Pie
								data={data.rate.map(({ _id, total }) => ({
									value: total,
									name: _id ? `${_id} оноо` : "Үнэлгээ өгөөгүй",
								}))}
								height={300}
								renderLabel={({ name, value }) => `${name}: ${value}`}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Report;
